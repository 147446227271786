.title {
  padding-bottom: 10px;
  float: left;
  display: contents;
}

.pillsMinRateButtons{
  background-color: #E6F2F4;
  border-color: #2e6da4;
}

.btn{
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none;
  color: #2e6da4;
  border-radius: 23px;
}

.seasonalBox{
  background-color: #E6F2F4;
}

.customRow {
  margin-left: 0px;
  margin-bottom: .5rem;
  display: inherit;
}

.whiteBackground {
  background-color: #FFFFFF;
}

.numberInput{
  width: 90px;
  float: right;
  margin: 0px 10px;
}

.textInput{
  width: 90px;
  float: right;
  margin: 0px 10px;
}

.selectType{
  border-radius: 5px;
  box-sizing: content-box;
  width: 185px;
  height: 30px;
  border: 1px solid #ced4da;
  margin: 0px 10px;
}

.seasonalCol{
  padding-right: 0px !important;
}