.title {
  padding-bottom: 10px;
  float: left;
}

.complianceBox {
  background-color: #F5F7F8;
  border: 0px solid !important;
  margin-top: 0px !important;
}

input.customCheckbox[type=checkbox] {
  margin-right: 12px !important;
  width: 20px;
  height: 20px;
}

input.customCheckbox[type="checkbox"]:checked{
  background-color:black !important;
}

.whiteBackground {
  background-color: #FFFFFF;
}

.customRow {
  margin-left: 0px;
  display: flex;
  margin-bottom: .5rem;
}