@import "src/styles/brand-colors";

.actionButtons {
    background-color: $midnight-4 !important;
    border: 0px;
    color: #4D7080 !important;
    padding-left: 0;
}

svg {
    width: 22px;
    height: 22px;
}

.roundedTable {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
}

.tableMidnight{
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 14px;
    background-color:$midnight-4;
    color: $midnight-40 !important;
    margin-bottom: 0 !important;
}

.tableMidnight th{
    color: $dusk-light !important;
}

.tableMidnight td{
    color: $dusk-light !important;
}

.lightHeader{
    font-weight: $dusk-light;
}

.floatRight {
    float: right;
}

.alertMidnight{
    background-color:$midnight-4;
    color: #41464b;
}

.modalButton > button{
    width: 100%;
}

.tableFooter {
    font-size: 14px;
}

.tableFooter b {
    font-weight: 600;
}

.valuesTable{
    display: inline-block;
}

.valuesTable{
    padding-bottom: 5%;
}