.removeBtn {
  padding: 0px 5px;
  float: right;
  background-color: #FFFFFF !important;
  border: 0px;
  color: #4D7080 !important;
}

.removeBtn:disabled {
  opacity: .65;
}

svg {
  width: 16px;
  height: 16px;
}