.titleShelf {
    padding: 20px 0;
    text-align: center;
}
.contentRules {
    height: 80vh;
    overflow: scroll;
    padding-right: -15px !important;
    padding-left: -15px !important;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.subtitle {
    color: #4D7180;
    font-weight: bolder;
}

.iconList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    div {
        display: flex;
        flex-direction: column;
        margin: 10px;
    }
    span {
        font-size: 12px;
        text-align: center;
    }
    svg {
        width: 50px;
        height: 50px;
        padding: 5px;
        background: white;
        border-radius: 5px;
    }
}

button {
    margin: 5px !important;
}

.counterRules {
    float: left;
    padding: 5px 16px 0px;
    font-size: 0.8rem;
    color: #4D7180;
}


