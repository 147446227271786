.collapseBtn {
  padding: 0px 5px;
  float: right;
  background-color: #FFFFFF !important;
  border: 0px;
  color: #4D7080 !important;
}

svg {
  width: 16px;
  height: 16px;
}
.title {
  padding-bottom: 10px;
  float: left;
  display: contents;
}
.restrictionArrow {
  vertical-align: sub;
}

.restrictionError {
  vertical-align: sub;
}

.headerInfo{
  float: right;
}
svg.headerInfoWarning>path{
  float: right;
  fill: red;
}