.title {
  padding-bottom: 10px;
  float: left;
  display: contents;
}

.restrictionArrow {
  vertical-align: sub;
}

.unitStayBox {
  border: none;
}

.unitStayBoxHeader {
  border: none;
  display: flex;
}

input.customCheckbox[type=checkbox] {
  margin-right: 12px !important;
  width: 20px;
  height: 20px;
}

input.customCheckbox[type="checkbox"]:checked{
  background-color:black !important;
}

.whiteBackground {
  background-color: #FFFFFF;
}

.customRow {
  margin-left: 0px;
  margin-bottom: .5rem;
}

.allowedReservations{
  padding: 15px;
  border-bottom-style: groove;
  border-bottom-width: 2px;
}

.effectiveDates{
  padding: 15px;
}

.checkInBox{
  background-color: #E6F2F4;
}

.nigthsBox{
  background-color: #EBEEE8;
}

.restrictionInputs{
  display: inline-block;
}

.inputBox{
  padding: 0px 0px;
}

.selectType{
  border-radius: 5px;
  box-sizing: content-box;
  width: 85px;
  height: 30px;
  border-color: #ccd6db;
  margin: 0px 10px;
}

.numberInput{
  width: 45px;
}

.separators{
  margin: 0px 15px;
}
.radioLabel{
  display: inherit;
}
.radioInput{
  margin: 0px 10px;
  vertical-align: middle;
}

.radioBox{
  display: inline;
}

.datesInputs{
  width: 90px;
  display: inline-block;
}

.radio{
  display: inline-block;
}

.btn{
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  padding: 6px 20px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none;
  color: #2e6da4;
  border-radius: 23px;
}

.pillsButtonsCheckin{
  background-color: #E6F2F4;
  border-color: #2e6da4;
}

.pillsButtonsNight{
  background-color: #EBEEE8;
  border-color: #2e6da4;
}

.buttonsBox{
  text-align: center;
}