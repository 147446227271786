.ruleItem {
    background-color: #FFFFFF;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccd6db;
    padding: 15px;
}

.littleFont {
    font-size: 0.9rem;
}

.detailsFont {
    font-size: 0.8rem;
}

.lock {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -10px;
}

.NoEditMessage {
    float: right;
    color: #D62E4F;
    font-size: 0.8rem;
}

.form-control:disabled {
    background-color: #E5EBED !important;
    color: #707579 !important;
}

.form-control[readonly] {
    background-color: #E5EBED !important;
    color: #707579 !important;
}

.customMarginLeft {
    margin-left: 10px !important;
}

.requiredLabel {
    padding: 6px 0px !important;
    float: left;
}

.headerTools {
    border-bottom: 1px solid #bfc2c3;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: -13px;
    margin-bottom: 15px;

}